import axios from "axios";
import {InterfaceAuth, InterfaceEmail, InterfaceForgotPwd, InterfaceLogin, RefreshToken, Token} from "../interface/InterfaceLogin";

const getCommonHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
  };
};


export const preLogin = (data: InterfaceEmail, url: string): Promise<any> => {
  const headers = getCommonHeaders();

  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/${url}/prelogin`, data,{ ...headers})
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

export const login = (data: InterfaceLogin, url: string): Promise<any> => {
  const headers = getCommonHeaders();

  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/${url}/login`, data,{ ...headers})
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

export const forgotPassword = (data: InterfaceEmail): Promise<any> => {
  const headers = getCommonHeaders();

  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/user/forgot-password`, data, {...headers})
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

export const authChallenge = (data: InterfaceAuth, sessionToken: Token | undefined, url: string): Promise<any> => {

  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/${url}/auth-challenge`, data, {
    headers: {
      'Content-Type': 'application/json',
      'x-session-token': sessionToken?.toString(),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

export const forgotPasswordConfirm = (data: InterfaceForgotPwd): Promise<any> => {
  const headers = getCommonHeaders();

  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/user/forgot-password/confirm`, data, {...headers})
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

export const refreshToken = (data: RefreshToken): Promise<any> => {
  const headers = getCommonHeaders();
  const pathname = window.location.pathname;
  let url = pathname.startsWith('/') ? pathname.substring(1) : pathname;
  if(url === 'login') {
    url = 'user';
  } else {
    url = 'admin';
  }
  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/${url}/refresh-token`, data,{ ...headers})
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

export const checkDNS = (data: string, idToken: string): Promise<any> => {

  return axios.post(`${process.env.REACT_APP_APIGATEWAY_URL}/admin/verify-dns`, {
    serverDns: data
  },{
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}` ,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error: any) => {
      throw error;
    });
}

