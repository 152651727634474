import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Home from "../views/Home";
import PageUnavaible from '../views/PageUnavaible';

const Router = () => {

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />}/>
      <Route path="/insitu" element={<div>Bienvenue sur insitu</div>}/>
      <Route path="/login" element={<Home/>}/>
      <Route path="/admin" element={<Home/>}/>
      <Route path="/login/reset" element={<Home/>}></Route>
      <Route path="*" element={<PageUnavaible/>}></Route>
    </Routes>
  );
}

export default Router;