import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import './style/reset.scss';
import {RunTypeProvider} from "./context/RunTypeContext";
import {BrowserRouter} from "react-router-dom";
import Router from "./router/Router";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <RunTypeProvider>
      <Router/>
    </RunTypeProvider>
  </BrowserRouter>
);
