import './Login.scss';
import situaction from "../../assets/img/situaction.png";
import logotypeGeoloc from "../../assets/svg/logotype_baseline_geoloc.svg";
import Input from "../../components_global/input/Input";
import {ArrowRight, User, Lock, ArrowLeft, HardDrives, CrownSimple, Users} from "@phosphor-icons/react";
import Checkbox from "../../components_global/checkbox/Checkbox";
import Button from "../../components_global/button/Button";
import React, {ChangeEvent, KeyboardEvent, FC, useContext, useEffect, useState, useRef} from "react";
import {RunTypeContext} from "../../context/RunTypeContext";
import {
  authChallenge,
  checkDNS,
  forgotPassword,
  forgotPasswordConfirm,
  login,
  preLogin,
  refreshToken
} from '../../service/ApiLogin';
import {areStringsEqual, isEmailValid, isEmpty, isPasswordValid} from "../../utils/Regex";
import {MessageError} from "../../assets/text/MessageError";
import {IconProps} from '../../interface/InterfaceLogin';
import {useLocation, useNavigate} from 'react-router-dom';
import { TabsType } from '../../interface/InterfaceComponent';
import Tabs from '../../components_global/tabs/Tabs';
import {MD5} from "crypto-js";
import Cookies from "js-cookie";

interface title {
  h1: string,
  h2: string
}

interface LoginProps {
  title: title,
  textBtn: string,
  optionActivate: boolean,
  variantInput?: string, //log, user, changePwd, admin
  action: string,  //continue, login, forgotPwd, newPwd
  originType?: string
}

const Login: FC<LoginProps> = ({title, textBtn, optionActivate, variantInput, action, originType}) => {
  const runTypeContext = useContext(RunTypeContext);

  const [inputValue, setInputValue] = useState<string>(runTypeContext?.user?.email ?? "");
  const [inputValuePwd, setInputValuePwd] = useState<string>("");
  const [inputValuePwdConfirm, setInputValuePwdConfirm] = useState<string>("");
  const [inputValueServer, setInputValueServer] = useState<string>("");
  const [inputError, setInputError] = useState<boolean>(false);
  const [inputErrorPwd, setInputErrorPwd] = useState<boolean>(false);
  const [inputErrorPwdConfirm, setInputErrorPwdConfirm] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(!!localStorage.getItem("email"));
  const [colorMessage, setColorMessage] = useState<string>('#26C485');
  const [message, setMessage] = useState<string>('');
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [code, setCode] = useState<string | null>('');
  const [tabsList, setTabsList] = useState<TabsType[]>([{
    text: 'Super Admin',
    icon: <CrownSimple size={24} weight="duotone" />,
    active: true
  }, {
    text: 'Compte client',
    icon: <Users size={24} weight="duotone" />,
    active: false
  }]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef1 = useRef<HTMLInputElement | null>(null);
  const inputRef2 = useRef<HTMLInputElement | null>(null);
  const inputRef3 = useRef<HTMLInputElement | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setCode(searchParams.get('code'));

    if (location.pathname === '/login/reset') {
      resetInput();
      runTypeContext?.setOriginType('forgotPwd');
      runTypeContext?.setRunType('newPwd');
      if (searchParams.get('code') === null || searchParams.get('code') === '') {
        navigate('/login');
      }
    }
  }, [location.search])

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.trim());

    if (action === "newPwd") {
      setInputError(!isPasswordValid(e.target.value.trim()));
    } else if (action === "admin") {
      setInputError(!isEmpty(e.target.value.trim()));
    } else if (action === "continue" || action === "login") {
      saveLocalStorage('email', e.target.value.trim());
    }
    else {
      setInputError(!isEmailValid(e.target.value.trim()));
    }
  }

  const inputHandlerPwd = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValuePwd(e.target.value);

    if (action === "newPwd") {
      setInputErrorPwd(!isPasswordValid(e.target.value));
      if(!isPasswordValid(e.target.value)) {
        setMessage(MessageError.passwordInvalid);
        setDisplayMessage(true);
        setColorMessage('#FF4747');
      } else {
        setDisplayMessage(false);
      }
    }
  }

  const inputHandlerPwdConfirm = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValuePwdConfirm(e.target.value);
  }

  const inputHandlerServer = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValueServer(e.target.value);
  }

  const redirectForgotPwd = () => {
    runTypeContext?.setOriginType(originType ?? "test");
    runTypeContext?.setRunType('forgotPwd');
  }

  const reset = () => {
    setInputValuePwd("");
    setInputValuePwdConfirm("");
    setDisplayMessage(false);
  }

  const resetInput = () => {
    setInputValue("");
  }


  const callApi = () => {
    switch (action) {
      case "continue":
        apiPreLogin();
        break;

      case "login" :
        apiLogin();
        break;

      case "newPwd":
        newPwd();
        break;

      case "forgotPwd":
        reset();
        apiForgotPwd();
        break;

      case "backPwd":
        setDisplayMessage(false);
        runTypeContext?.setRunType('preLog');
        break;

      case "redirect":
        navigate('/login');
        runTypeContext?.setRunType('preLog');
        break;

      case "admin":
        setLoadingButton(true);
        if(tabsList[0].active) {
          superAdmin();
        } else {
          loginAs();
        }
        break;

      default:
        break;
    }
  }

  const apiPreLogin = () => {
    setColorMessage('#FF4747');
    setDisplayMessage(false);

    if (isEmpty(inputValue)) {
      setInputError(true);
      setMessage(MessageError.enterLogin);
      setDisplayMessage(true);

    } else if (!isEmailValid(inputValue)) {
      setInputError(true);
      setMessage(MessageError.emailInvalid);
      setDisplayMessage(true);

    } else {
      setLoadingButton(true);
      preLogin(
        {
          email: inputValue
        }, runTypeContext?.url ?? 'user'
      ).then((res) => {
        const status = res.status;
        switch (status) {
          case "CONFIRMED" :
            setInputError(false);
            runTypeContext?.setRunType('login');
            runTypeContext?.setOriginType('preLog');
            break;

          case "FORCE_CHANGE_PASSWORD":
            runTypeContext?.setRunType('temporaryLog');
            runTypeContext?.setOriginType('preLog');
            break;

          case "USER_DISABLED":
            setInputError(true);
            setMessage(MessageError.accountDisabled);
            setDisplayMessage(true);
            break;

          case 'UNAUTHORIZED':
            setInputError(true);
            setMessage(MessageError.unauthorized);
            setDisplayMessage(true);
            break;

          default:
            setMessage(MessageError.tryAgainLater);
            setDisplayMessage(true);
            break;
        }
        setLoadingButton(false);

      }).catch((error) => {
        errorPreLogin(error);
      });
    }
  }

  const apiLogin = () => {
    setColorMessage('#FF4747');
    setDisplayMessage(false);

    if (isEmpty(inputValue) && isEmpty(inputValuePwd)) {
      setInputError(true);
      setInputErrorPwd(true);
      setMessage(MessageError.inputEmpty);
      setDisplayMessage(true);

    } else if (!isEmailValid(inputValue)) {
      setInputError(true);
      setMessage(MessageError.emailInvalid);
      setDisplayMessage(true);

    } else if (isEmpty(inputValue)) {
      setInputError(true);
      setMessage(MessageError.enterLogin);
      setDisplayMessage(true);

    } else if (isEmpty(inputValuePwd)) {
      setInputErrorPwd(true);
      setMessage(MessageError.enterPwd);
      setDisplayMessage(true);

    } else {
      setLoadingButton(true);
      login(
        {
          email: inputValue,
          password: inputValuePwd,
          hashPassword: MD5(inputValuePwd).toString()
        }, runTypeContext?.url ?? 'user'

      ).then((res) => {
        const status = res.status;
        switch (status) {
          case 'CONFIRMED':
            runTypeContext?.setUser({
              email: inputValue,
              token: res
            });

            if (runTypeContext?.url === "admin") {
              reset();
              resetInput();
              runTypeContext?.setRunType('admin');
              runTypeContext?.setOriginType('login');
            } else {
              window.location.href = process.env.REACT_APP_URL_REDIRECT || 'https://authentification.situaction.fr';
            }
            break;

          case 'NEW_PASSWORD_REQUIRED':
            runTypeContext?.setRunType('newPwd');
            runTypeContext?.setOriginType('firstLogin');
            runTypeContext?.setUser({
              email: inputValue,
              token: res.sessionToken
            });
            reset();
            resetInput();
            break;

        }
        setLoadingButton(false);

      }).catch((error) => {
        setInputErrorPwd(true);
        setDisplayMessage(true);

        const status = error.response.data.status;
        switch (status) {
          case 'INTERNAL_SERVER_ERROR':
            setMessage(MessageError.tryAgainLater);
            break;

          case 'USER_NOT_FOUND':
            setMessage(MessageError.infoInvalid);
            break;

          case 'UNAUTHORIZED':
            setMessage(MessageError.unauthorized);
            break;

          default:
          console.error(error);
          setMessage(MessageError.tryAgainLater);
          break;
        }
        setLoadingButton(false);
      });
    }
  }

  const newPwd = () => {
    setColorMessage('#FF4747');
    setDisplayMessage(false);

    if (isEmpty(inputValuePwdConfirm) && isEmpty(inputValuePwd)) {
      setInputErrorPwd(true);
      setInputErrorPwdConfirm(true);
      setMessage(MessageError.enterPwd);
      setDisplayMessage(true);

    } else if (!areStringsEqual(inputValuePwdConfirm, inputValuePwd)) {
      setInputErrorPwdConfirm(true);
      setInputErrorPwd(true);
      setMessage(MessageError.samePwd);
      setDisplayMessage(true);

    } else if (!isPasswordValid(inputValuePwdConfirm)) {
      setInputErrorPwdConfirm(true);
      setInputErrorPwd(true);
      setMessage(MessageError.passwordInvalid);
      setDisplayMessage(true);

    } else {
      if (runTypeContext?.originType === "firstLogin") {
        apiAuthChallenge();
      } else {
        apiNewPwd();
      }
    }
  }

  const apiAuthChallenge = () => {
    authChallenge({
        email: runTypeContext?.user?.email ?? "",
        newPassword: inputValuePwd
      },
      runTypeContext?.user?.token, runTypeContext?.url ?? 'user'
    ).then((res) => {
      runTypeContext?.setUser({
        email: runTypeContext?.user?.email ?? "",
        token: res
      });
      window.location.href = process.env.REACT_APP_URL_REDIRECT || 'https://authentification.situaction.fr';

    }).catch((error) => {
      setDisplayMessage(true);
      const status = error.response.data.status;

      switch (status) {
        case 'INTERNAL_SERVER_ERROR':
          setMessage(MessageError.tryAgainLater);
          break;
        case 'INVALID_TOKEN':
          setMessage(MessageError.invalidSession);
          break;
        case 'UNAUTHORIZED':
          setMessage(MessageError.unauthorized);
          break;
        default:
          setMessage(MessageError.tryAgainLater);
          break;
      }
    })
  }

  const apiNewPwd = () => {
    if (isEmpty(inputValue)) {
      setInputError(true);
      setMessage(MessageError.enterLogin);
      setDisplayMessage(true);

    } else if (!isEmailValid(inputValue)) {
      setInputError(true);
      setMessage(MessageError.emailInvalid);
      setDisplayMessage(true);

    } else {
      setLoadingButton(true);

      forgotPasswordConfirm(
        {
          "email": inputValue,
          "password": inputValuePwd,
          "confirmationCode": code ?? '0'
        }
      ).then((res) => {
        runTypeContext?.setRunType('valideChangePwd');
        runTypeContext?.setOriginType('forgotPwd');
        reset();
        setLoadingButton(false);
        setInputValue(runTypeContext?.user?.email ?? "");

      }).catch((error) => {
        console.log(error)
        setLoadingButton(false);
        setDisplayMessage(true);
        switch (error.response.data.status) {
          case "EXPIRED_CODE":
            setMessage(MessageError.expiredCode);
            break;

          default:
            setMessage(MessageError.tryAgainLater);
            break;
        }
      });

      resetInput();
      reset();
    }
  }

  const apiForgotPwd = () => {
    setLoadingButton(true);
    setDisableButton(true);
    forgotPassword({
      email: inputValue
    }).then((res) => {
      runTypeContext?.setOriginType('forgotPwd');
      setColorMessage('#26C485');
      setMessage(MessageError.checkEmail);
      setDisplayMessage(true);
      setLoadingButton(false);
      setDisableButton(false);

    }).catch((error) => {
      if(error.response.status === 500) {
        setColorMessage('#FF4747');
        setDisplayMessage(true);
        setMessage(MessageError.limitSendEmail);
        setLoadingButton(false);
        setDisableButton(false);

      } else {
        errorForgotPwd();
      }
    })
    runTypeContext?.setRunType('backLogin');
  }

  const superAdmin = () => {
    if(Cookies.get('AccessToken') && runTypeContext?.user?.token?.IdToken) {
      if(inputValueServer !== "") {
        checkDNS(inputValueServer, runTypeContext?.user?.token?.IdToken).then((res) => {
          if(runTypeContext?.user?.token?.ExpiresIn) {
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + runTypeContext?.user?.token?.ExpiresIn * 1000);
            const secureFlag = process.env.REACT_APP_COOKIES_SECURE === 'true';  //passer en true pour le https
            const cookieOptions = { domain: process.env.REACT_APP_DOMAIN ,expires: expirationDate, secure: secureFlag};
            Cookies.set('DNSServerSA',  inputValueServer, cookieOptions);
            Cookies.set('RefreshToken',  runTypeContext?.user?.token?.RefreshToken, cookieOptions);
            window.open(process.env.REACT_APP_URL_REDIRECT || 'https://authentification.situaction.fr', '_blank');
            setDisplayMessage(false);
            setLoadingButton(false);
            reset();
          }
        }).catch((error) => {
          setColorMessage('#FF4747');
          setDisplayMessage(true);
          setLoadingButton(false);

          if(error.response.status === 401) {
            setMessage(MessageError.unauthorized);

          } else if (error.response.status === 400) {
            if(error.response.data.errorType === "BODY_INCOMPLETE") {
              setMessage(MessageError.bodyIncomplete);

            } else if(error.response.data.errorType === "NOT_FOUND") {
              setMessage(MessageError.serverInvalid);

            } else if(error.response.data.errorType === "BAD_DOMAIN") {
              setMessage(MessageError.badDomain);

            } else if(error.response.data.errorType === 'UNAUTHORIZED'){
              setMessage(MessageError.unauthorized);
              runTypeContext?.setRunType('login');
              runTypeContext?.setOriginType('preLogin');

            } else {
              setMessage(MessageError.tryAgainLater);
            }

          } else {
            setMessage(MessageError.tryAgainLater);
          }
        })

      } else {
        setColorMessage('#FF4747');
        setMessage(MessageError.enterServer);
        setDisplayMessage(true);
        setLoadingButton(false);
      }
    }
  }

  const loginAs = () => {
    setColorMessage('#FF4747');
    if (isEmpty(inputValue)) {
      setInputError(true);
      setMessage(MessageError.enterLogin);
      setDisplayMessage(true);

    } else {
      if(runTypeContext?.user?.email && runTypeContext?.user?.token?.RefreshToken) {
        refreshToken({
          email: runTypeContext?.user?.email,
          refreshToken: runTypeContext?.user?.token?.RefreshToken,
        }).then(res => {
          preLogin(
            {
              email: inputValue
            }, 'user'
          ).then((res) => {
            if(runTypeContext?.user?.token?.ExpiresIn) {
              const expriresIn = runTypeContext?.user?.token?.ExpiresIn;
              const expirationDate = new Date();
              expirationDate.setTime(expirationDate.getTime() + expriresIn * 1000);
              const secureFlag = process.env.REACT_APP_COOKIES_SECURE === 'true';  //passer en true pour le https
              const cookieOptions = { domain: process.env.REACT_APP_DOMAIN, expires: expirationDate, secure: secureFlag};
              Cookies.set('IdLoginAs', inputValue, cookieOptions);
              Cookies.set('RefreshToken',  runTypeContext?.user?.token?.RefreshToken, cookieOptions);
              window.open(process.env.REACT_APP_URL_REDIRECT || 'https://authentification.situaction.fr', '_blank');
              setLoadingButton(false);
              reset();
            } else {
              setLoadingButton(false);
              setDisplayMessage(true);
              setMessage(MessageError.tryAgainLater);
            }
          }).catch((error) => {
            errorPreLogin(error);
          });

        }).catch(error => {
          if (error.response.data.status === "INVALID_TOKEN") {
            runTypeContext?.setRunType('login');
            runTypeContext?.setOriginType('preLogin');
          }
        })
      }
    }
  }

  const errorPreLogin = (error: any) => {
    const status = error.response.data.status;

    switch (status) {
      case "INVALID_INPUT":
        setMessage(MessageError.changeEmail);
        break;

      case "RESET_REQUIRED":
        setMessage(MessageError.checkEmail);
        break;

      case "USER_NOT_FOUND":
        setMessage(MessageError.identifierUnknown);
        break;

      case "INTERNAL_SERVER_ERROR":
        setMessage(MessageError.tryAgainLater);
        break;

      case 'UNAUTHORIZED':
        setMessage(MessageError.unauthorized);
        break;

      default:
        setMessage(MessageError.tryAgainLater);
        break;
    }
    console.error(error)
    setInputError(true);
    setDisplayMessage(true);
    setLoadingButton(false);
  }

  const errorForgotPwd = () => {
    setColorMessage('#FF4747');
    setDisplayMessage(true);
    setMessage(MessageError.sendMail);
    setLoadingButton(false);
  }

  const saveLocalStorage = (key: string, value: string) => {
    if (isChecked && value !== "") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
  }

  const returnPreLogin = () => {
    runTypeContext?.setRunType('preLog');
    setDisableButton(false);
    if (displayMessage) {
      runTypeContext?.setOriginType('temporaryLog');
    } else {
      reset();
    }
    setDisplayMessage(false);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, currentIndex: number, numberOfInputs: number, inputRef?: React.RefObject<HTMLInputElement> | null) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (currentIndex === numberOfInputs) {
        // If this is the last input field, focus on the button
        if(buttonRef.current !== null) {
          buttonRef.current.focus();
          buttonRef.current.click();
        }

      } else {
        // Otherwise, focus on the following input field
        if(inputRef) {
          inputRef.current?.focus();
        }
      }
    }
  };

 useEffect(() => {
   saveLocalStorage('email', inputValue);
 }, [isChecked])

  const displayInput = () => {
    switch (variantInput) {
      case "log" :
        return (
          <div className="flexVertical gap16 width100">
            <Input type="text"
                   icon={<User size={22} weight="duotone"/>}
                   labelColor="#FFFFFF"
                   label={`Identifiant ${location.pathname === "/login" ? "utilisateur": "support"}`}
                   error={inputError}
                   onInput={inputHandler}
                   value={inputValue}
                   ref={inputRef1}
                   onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, 1,2, inputRef2)}
                   name="username"
            />
            <Input type="password"
                   icon={<Lock size={22} weight="duotone"/>}
                   labelColor="#FFFFFF"
                   label="Mot de passe"
                   error={inputErrorPwd}
                   onInput={inputHandlerPwd}
                   value={inputValuePwd}
                   ref={inputRef2}
                   onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, 2,2)}
                   name="password"
            />
          </div>
        );

      case "user":
        return <Input type="text"
                      icon={<User size={22} weight="duotone"/>}
                      labelColor="#FFFFFF"
                      label= {`Identifiant ${location.pathname === "/login" ? "utilisateur": "support"}`}
                      error={inputError}
                      onInput={inputHandler}
                      value={inputValue}
                      ref={inputRef1}
                      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e, 1,1, inputRef2)}
                      name="username"
        />;

      case "changePwd":
        return (
          <div className="flexVertical gap16 width100">
            {runTypeContext?.originType === "firstLogin" ? '':
              <Input type="text"
                     icon={<User size={22} weight="duotone"/>}
                     labelColor="#FFFFFF"
                     label="Identifiant utilisateur"
                     error={inputError}
                     onInput={inputHandler}
                     value={inputValue}
                     ref={inputRef1}
                     onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                       handleKeyDown(e, 1,runTypeContext?.originType === "firstLogin" ? 0 : 3, runTypeContext?.originType === "firstLogin" ? null : inputRef2)}
               name="password"/>
            }
            <Input type="password"
                   icon={<Lock size={22} weight="duotone"/>}
                   labelColor="#FFFFFF"
                   label="Mot de passe"
                   error={inputErrorPwd}
                   onInput={inputHandlerPwd}
                   value={inputValuePwd}
                   ref={inputRef2}
                   onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                     handleKeyDown(e, runTypeContext?.originType === "firstLogin" ? 1 : 2,runTypeContext?.originType === "firstLogin" ? 2 : 3, inputRef3)}
                   name="password"
            />
            <Input type="password"
                   icon={<Lock size={22} weight="duotone"/>}
                   labelColor="#FFFFFF"
                   label="Confirmer le mot de passe"
                   error={inputErrorPwdConfirm}
                   onInput={inputHandlerPwdConfirm}
                   value={inputValuePwdConfirm}
                   ref={inputRef3}
                   onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                     handleKeyDown(e, runTypeContext?.originType === "firstLogin" ? 2 : 3,runTypeContext?.originType === "firstLogin" ? 2 : 3)}
                   name="password"
            />
          </div>
        );

      case 'text' :
        return (
          <div className="greenColor fontSize16 textAlignCenter">
            Votre changement de mot de passe a été pris en compte, vous pouvez fermer cet onglet ou vous connecter.
          </div>
        );

      case 'admin':
        return (
          <div className="flexVertical gap16 width100">
            <Tabs item={tabsList} setItem={setTabsList}/>
            {contentTabsDisplay()}
          </div>
        );

      default:
        return;
    }
  }

  const contentTabsDisplay = () => {
    if(tabsList[0].active) {
      return <Input type="text"
                    icon={<HardDrives size={22} weight="duotone"/>}
                    labelColor="#FFFFFF"
                    label="Serveur"
                    error={false}
                    onInput={inputHandlerServer}
                    value={inputValueServer}
                    ref={inputRef1}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                      handleKeyDown(e, 1,1)}
                    name="server"
      />;
    } else {
      return <div className="flexVertical gap16 width100">
        <Input type="text"
               icon={<User size={22} weight="duotone"/>}
               labelColor="#FFFFFF"
               label="Identifiant utilisateur"
               error={false}
               onInput={inputHandler}
               value={inputValue}
               ref={inputRef1}
               onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                 handleKeyDown(e, 1,2)}
               name="username"
        />
      </div>
    }

  }

  const styleMessage: React.CSSProperties = {
    color: colorMessage
  }

  return (
    <div className="content flexVertical gap32 radius18 width80 height60 borderBox flexWrap">
      <img src={logotypeGeoloc} alt="logo situaction" className="geoloc"/>
      <img src={situaction} alt="logo situaction geoloc" className="absolute situaction logotypeGeoloc"/>
      <div className="flexVertical">
        <h1 className="unbounded fontSize24 mB4 mT50">{title.h1}</h1>
        <h2 className={runTypeContext?.runType === "temporaryLog" ? "greenColor" : ""}>{title.h2}</h2>
      </div>
      <div className="flexVertical width80">
        {action === "forgotPwd" && (
          <div className="flexHorizontal gap4 mB8 width100 fontSize16">
            <Button text="Retour à la connexion"
                    color="primary"
                    variant="text"
                    iconLeft
                    icon={ArrowLeft as React.ComponentType<IconProps>}
                    onClick={() => returnPreLogin()}/>
          </div>
        )}
        <form>
          {displayInput()}
        </form>
        {(optionActivate && runTypeContext?.url !== "admin") && (
          <div className="fontSize16 fontWeight600 flexHorizontalSpaceBetweenCenter mT16 width100">
            <Checkbox text="Se rappeler de moi"
                      checked={runTypeContext?.user?.email !== undefined}
                      setChecked={setIsChecked}/>
            <Button text="Mot de passe oublié ?"
                    color="primary"
                    variant="text"
                    onClick={() => redirectForgotPwd()}/>
          </div>
        )}
      </div>
      <Button text={textBtn}
              color="primary"
              variant="contained"
              loading={loadingButton}
              icon={ArrowRight as React.ComponentType<IconProps>}
              onClick={() => callApi()}
              onKeyDown={() => callApi()}
              ref={buttonRef}
              disable={disableButton}
      />
      {displayMessage && (
        <div style={styleMessage}>{message}</div>
      )}
      <div className="absolute bottom35 width100 textAlignCenter">
        <div className="footerLogin">
          <Button text="Contactez-nous"
                  color="primary"
                  variant="text"
                  onClick={() => {
                    window.open('https://www.situaction.fr/contact-situaction/')
                  }}/>
          <img src={logotypeGeoloc} alt="logo situaction geoloc" className="logotypeGeoloc"/>
        </div>
      </div>
    </div>
  );
}

export default Login;