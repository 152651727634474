
export const MessageError = {
  passwordInvalid: "Votre mot de passe ne contient pas 6 caractères minimum.",
  infoInvalid: "Votre utilisateur et/ou mot de passe sont/est incorrecte.",
  identifierUnknown: "Identifiant inconnu.",
  enterLogin: "Tapez votre identifiant.",
  enterPwd: "Tapez votre mot de passe.",
  emailInvalid: "Votre email est incorrect.",
  accountDisabled: "Votre compte est désactivé.",
  tryAgainLater: "Une erreur s'est produite, veuillez réessayer plus tard.",
  checkEmail: "Un email de récupération vous a été envoyé.",
  inputEmpty: "Tapez votre email et mode de passe.",
  invalidSession: "Votre session a expirée.",
  samePwd: "Les champs ne sont pas identique.",
  sendMail: "Le mail n'a pas été envoyé, veuillez recommencer.",
  enterServer : "Entrer le serveur.",
  serverInvalid : "Le serveur n'existe pas.",
  unauthorized: "Vous n'êtes pas autorisé.",
  bodyIncomplete: "Le champs serveur est vide.",
  badDomain: "Le domaine doit être situaction.fr",
  limitSendEmail: "Limite de tentatives dépassée, veuillez réessayer plus tard.",
  changeEmail: "Tapez votre email",
  expiredCode: "Le lien a expiré."
}