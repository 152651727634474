/** Regex export file **/

export const isEmailValid = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
  return emailRegex.test(email);
};

export const areStringsEqual = (string1: string, string2: string): boolean => {
  return string1 === string2;
};

export const isPasswordValid = (password: string): boolean => { //6 caract mini
  const passwordRegex = /^.{5,}$/;
  return passwordRegex.test(password);
};

export const isEmpty = (text: string): boolean => {
  return text.trim() === '';
}
