import React, {FC, useEffect, useState} from 'react';
import './Tabs.scss'
import {TabsType} from '../../interface/InterfaceComponent';

interface TabsProps {
  item: TabsType[]
  setItem: (item: TabsType[]) => void
}

const Tabs:FC<TabsProps> = ({item , setItem}) => {
  const [toggleState, setToggleState] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    updateLineWidth();

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  const updateLineWidth = () => {
    const tabElement = document.getElementById(`tab-${toggleState}`);
    const lineElement = document.getElementById('line');
    if (tabElement && lineElement) {
      lineElement.style.width = `${tabElement.offsetWidth}px`;
      lineElement.style.transform = `translateX(${tabElement.offsetWidth * toggleState }px)`;
    }
  };

  const changeSelect = (index: number) => {
    setToggleState(index)

    const updatedItem = item.map((tab, id) => {
      if (index === id) {
        return { ...tab, active: true };
      } else {
        return { ...tab, active: false };
      }
    });

    setItem(updatedItem);
  }

  return (
    <div className="width100">
      <ul className="flexHorizontalSpaceEvenlyCenter pB8 fontSize16 fontWeight600">
        {item.map((tab, index) => (
          <li key={index} id={`tab-${index}`} onClick={() => changeSelect(index)} className={`flexHorizontalCenter width100 gap8 pointer ${toggleState !== index ? "disableTabs" : ""}`}>
            <div>{tab.icon}</div>
            <div>{tab.text}</div>
          </li>
        ))}
      </ul>
      <div className="relative">
        <div id="line" className="line"></div>
        <div className="separator width100"></div>
      </div>
    </div>
  );
}

export default Tabs;