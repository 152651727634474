import './Checkbox.scss';
import React, {FC, useState} from "react";
import {Check} from "@phosphor-icons/react";

interface CheckboxProps {
  text: string,
  disable?: boolean,
  checked?: boolean,
  setChecked: (checked: boolean) => void,
}

const Checkbox:FC<CheckboxProps> = ({text, disable, checked, setChecked}) => {
  const [isChecked, setIsChecked] = useState<boolean | undefined>(checked ?? false);

  const handleCheckboxChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    setChecked(newChecked);
  };

  return(
    <div className="checkbox"
         onClick={handleCheckboxChange}
    >
      <div className={disable ? 'disable' : (isChecked ? 'checked' : 'initial')}>
        {isChecked && (
          <Check size={16} color="white" weight="bold" />
        )}
      </div>
      <label className={disable ? 'disableLabel' : ''}>{text}</label>
    </div>
  );
}

export default Checkbox;