import '../style/pageUnavaible.scss';
import Button from "../components_global/button/Button";
import {useNavigate} from "react-router-dom";
import {ArrowUUpLeft} from "@phosphor-icons/react";
import React from "react";
import {IconProps} from "../interface/InterfaceLogin";
import vroomy404 from "../assets/svg/404-vroomy.svg";
import logotypeGeoloc from "../assets/svg/logotype_baseline_geoloc.svg";

const PageUnavaible = () => {
  const navigate = useNavigate();

  return (
    <div className="unavaible fullWidthHeight flexVerticalCenter gap32 primaryColor">
      <img className="absolute logo" alt="logo situaction" src={logotypeGeoloc}/>
      <img className="vroomy404" alt="404" src={vroomy404}/>
      <div className="fontSize32 fontWeight800 textAlignCenter">
        Ici, aucune information de géolocalisation à signaler.
      </div>
      <div className="fontSize20 fontWeight500 textAlignCenter width600">
        La page que vous recherché n’existe pas ou n’est plus disponible, vous pouvez fermer cet onglet ou revenir en arrière.
      </div>
      <Button text="Revenir à l’accueil" color="primary" variant="contained"
              iconLeft icon={ArrowUUpLeft as React.ComponentType<IconProps>} onClick={() => navigate('/login')}/>
    </div>
  );
}

export default PageUnavaible;