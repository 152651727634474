import './Input.scss';
import {EyeSlash, Eye} from "@phosphor-icons/react";
import {
  ReactElement,
  ChangeEventHandler,
  KeyboardEventHandler,
  useState,
  useEffect, forwardRef, Ref,
} from "react";

interface InputProps {
  color?: string, //success, error, primary
  type: string,
  icon?: ReactElement,
  disabled?: boolean,
  label: string,
  labelColor: string,
  error: boolean,
  value?: string,
  onInput: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  name: string
}

const Input = forwardRef((props : InputProps, ref: Ref<HTMLInputElement>) => {
  const {color, type, icon, disabled, label, labelColor, error, value, onInput, onKeyDown, name} = props;

  const [isColor, setIsColor] = useState<string>(color ?? 'primary');
  const [password, setPassword] = useState<boolean>(type === "password");
  const [visible, setVisible] = useState<boolean>(false);


  const handlePasswordVisibilty = () => {
    setVisible(!visible);
  }

  useEffect(() => {
    setPassword(type === "password");
  } , [type])

  useEffect(() => {
    if (ref) {
      if ('current' in ref && ref.current && type === 'password') {
        ref.current.type = visible ? "text" : "password";
      }
    }
  }, [visible]);


  return (
    <div className={`${disabled ? "disabled" : error ? "error" : isColor} input-label-container urbanist radius8 flexHorizontal width100 fontWeight600 ${error ? "inputError" : ""}`}>

      {icon && (<div className={disabled ? 'iconDisabled' : 'iconColor'}>{icon}</div>)}

      <input ref={ref} disabled={disabled}
             className='mL8 floating-input urbanist fontWeight500'
             type={type} placeholder=" "
             onChange={onInput}
             value={value}
             onKeyDown={onKeyDown}
             name={name}
      />

      <label style={{backgroundColor: labelColor}}
             className={`${icon ? "leftIcon" : "leftWithoutIcon"} ${isColor} floating-label labelColor urbanist`}>{label}</label>

      {password && !visible && (
        <EyeSlash size={22} weight="duotone" onClick={handlePasswordVisibilty}/>
      )}
      {password && visible && (
        <Eye size={22} weight="duotone" onClick={handlePasswordVisibilty}/>
      )}


    </div>
  );
})

export default Input;