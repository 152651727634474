import React, {createContext, FC, ReactElement, useEffect, useMemo, useState} from "react";
import {User} from "../interface/InterfaceLogin";
import Cookies from 'js-cookie';
import { Token } from "../utils/Token";
import {refreshToken} from "../service/ApiLogin";
import {useLocation} from "react-router-dom";

export interface RunTypeContext {
  runType: string
  setRunType: (runType: string) => void
  setOriginType: (originType: string) => void
  originType: string
  user?: User
  setUser: (user: User) => void
  url: string
}

export let RunTypeContext = createContext<RunTypeContext | null>(null)

interface RunTypeProviderProps {
  children: ReactElement | ReactElement[],
}

export const RunTypeProvider: FC<RunTypeProviderProps> = ({children}) => {
  const [runType, setRunType] = useState<string>('preLog');
  const [originType, setOriginType] = useState<string>('preLog');
  const [user, setUser] = useState<User>();
  const [url, setUrl] = useState<string>('user');
  const location = useLocation();

  useMemo(() => {
    if(localStorage.getItem('email') !== null) {
      setUser({
        token: user?.token,
        email: JSON.parse(localStorage.getItem('email')!)
      })
    }
  }, [localStorage.getItem('email')]);


  useEffect(() => {
    const pathname = window.location.pathname;
    const url = pathname.startsWith('/') ? pathname.substring(1) : pathname;
    if(url === 'admin') {
      setUrl('admin');
    } else {
      setUrl('user');
    }
  }, [location.pathname])

  /** add token to cookies **/
  useEffect(() => {
    if (user?.token !== undefined) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + user?.token.ExpiresIn * 1000);
      const secureFlag = process.env.REACT_APP_COOKIES_SECURE === 'true';  //passer en true pour le https
      const cookieOptions = { domain: process.env.REACT_APP_DOMAIN ,expires: expirationDate, secure: secureFlag};

      Cookies.set('AccessToken',  user?.token.AccessToken, cookieOptions);
      Cookies.set('RefreshToken',  user?.token.RefreshToken, cookieOptions);
    }
  }, [user?.token])

  /** refresh token if there are cookies token **/
 useEffect ( () => {
   const accessToken = Cookies.get('AccessToken');
   const refreshToken = Cookies.get('RefreshToken');
   const pathname = window.location.pathname;
   const url = pathname.startsWith('/') ? pathname.substring(1) : pathname;

   if(url === 'admin') {
     Cookies.remove('AccessToken');
     Cookies.remove('RefreshToken');
     Cookies.remove('DNSServerSA');
     Cookies.remove('IdLoginAs');
   } else {
     if (accessToken && refreshToken && refreshToken !== 'undefined') {
       let decoded = Token.decode(accessToken);
       if(decoded !== null) {
         let email = decoded?.username;
         refresh(email, refreshToken);
       }
     }
   }
 }, [])

  const refresh = (email:string, refresh: string) => {
    refreshToken(
      {
        email: email,
        refreshToken: refresh,
      }
    ).then((res) => {
      setUser({
        email: email,
        token: {
          AccessToken: res.AccessToken,
          ExpiresIn: res.ExpiresIn,
          IdToken: res.IdToken,
          RefreshToken: refresh,
          TokenType: res.TokenType
        }
      });
      window.location.href = process.env.REACT_APP_URL_REDIRECT || 'https://authentification.situaction.fr';

    }).catch((error) => {
      console.error(error)
    });
  }


  const value = {runType, setRunType, originType, setOriginType, user, setUser, url};

  return <RunTypeContext.Provider value={value}>{children}</RunTypeContext.Provider>;
}
