import './Button.scss';
import React, {forwardRef, KeyboardEventHandler, KeyboardEvent, MouseEventHandler, Ref} from "react";
import { Spinner } from '@phosphor-icons/react';
import { IconProps } from '../../interface/InterfaceLogin';

interface ButtonProps {
  text: string,
  color: string,  //success, error, primary, secondary
  icon?: React.ComponentType<IconProps>,
  iconLeft?: boolean,
  loading?: boolean,
  disable?: boolean,
  variant: string,  //text, contained, outlined
  onClick: MouseEventHandler<HTMLButtonElement>,
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>
}

const Button = forwardRef(( props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
  const { text, color, icon: IconComponent, iconLeft, loading, disable, variant, onClick, onKeyDown } = props;

  const buttonStyle: React.CSSProperties = {
    cursor: disable ? 'not-allowed' : 'pointer',
    pointerEvents: loading ? 'none' : 'auto',
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      if (onKeyDown) {
        onKeyDown(event);
      }
    }
  };

  return (
    <div className="button">
      <button className={disable ? 'disable '+ variant : color +' '+ variant}
              style={buttonStyle}
              onClick={onClick}
              onKeyDown={handleKeyDown}
              ref={ref}
              disabled={disable || loading}
      >
        {loading ?
          <Spinner className="spinner" size={18} weight="regular"/>
          :
          <div>
            {iconLeft && IconComponent &&
              <IconComponent weight="duotone" size={24}/>
            }
            <div>{text}</div>
            {!iconLeft && IconComponent &&
              <IconComponent weight="duotone" size={24}/>
            }
          </div>
        }
      </button>
    </div>
  );

})
export default Button;