import {TokenDecode} from "../interface/InterfaceLogin";
import jwt_decode from "jwt-decode";

const isTokenValid = (exp: number): boolean => {
    const currentTime = Math.floor(Date.now() / 1000);
    return exp >= currentTime;
};

const decodeToken = (accessToken: string): TokenDecode | null => {
  try {
    const decodedToken: TokenDecode = jwt_decode(accessToken);
    return decodedToken;
  } catch (error) {
    console.error('Erreur lors du décodage du token:', error);
    return null;
  }
};

export const Token = {
  isTokenValid,
  decode: decodeToken,
};



