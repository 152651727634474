import '../style/home.scss';
import React, {useContext} from "react";
import Login from "../components/login/Login";
import {RunTypeContext} from "../context/RunTypeContext";
import summer from "../assets/img/summer.png";
import spring from "../assets/img/spring.png";
import winter from "../assets/img/winter.png";
import automn from "../assets/img/automn.png";

const Home = () => {
  const runTypeContext = useContext(RunTypeContext);

  const titleConnexion = {
    h1 : "Connectez-vous",
    h2 : "heureux de vous revoir !",
  }
  const titleTemporaryConnexion = {
    h1 : "Connectez-vous",
    h2 : "Entrer votre mot de passe provisoire reçu par mail.",
  }
  const titlePassword = {
    h1 : "Mot de passe",
    h2 : "Veuillez renouveler votre mot de passe",
  }
  const titleForgotPassword = {
    h1 : "Problème de connexion",
    h2 : "Mot de passe oublié",
  }

  const displayView = (): React.ReactNode => {
    switch (runTypeContext?.runType) {
      case "preLog":
        return <Login action="continue" textBtn="Continuer" optionActivate title={titleConnexion} variantInput="user" originType="preLog"/>;
      case "login":
        return <Login action="login" textBtn="Se connecter" optionActivate title={titleConnexion} variantInput="log" originType="login"/>;
      case "temporaryLog":
        return <Login action="login" textBtn="Se connecter" optionActivate title={titleTemporaryConnexion} variantInput="log" originType="temporaryLog"/>;
      case "newPwd":
        return <Login action="newPwd" textBtn="Valider" optionActivate={false} title={titlePassword} variantInput="changePwd"/>;
      case "forgotPwd":
        return <Login action="forgotPwd" textBtn="Récupérer son mot de passe" optionActivate={false} title={titleForgotPassword} variantInput="user"/>;
      case "backLogin":
        return <Login action="backPwd" textBtn="Retour à la connexion" optionActivate={false} title={titleForgotPassword}/>;
      case "valideChangePwd":
        return <Login action="redirect" textBtn="Se connecter" optionActivate={false} title={titlePassword} variantInput="text"/>;
      case "admin":
        return <Login action="admin" textBtn="Se connecter" optionActivate={false} title={titleConnexion} variantInput="admin"/>;
      default:
        return <Login action="continue" textBtn="Continuer" optionActivate={false} title={titleConnexion} variantInput="user" originType="classicLog"/>;
    }
  }

  const getImage = () => {
    const date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if((month === 3 && day >= 21) || (month <= 5 && month >= 4) || (month === 6 && day <= 20)){
      return spring;

    } else if ((month === 6 && day >= 21) || (month <= 8 && month >= 7) || (month === 9 && day <= 20)) {
      return summer;

    } else if ((month === 9 && day >= 21) || (month <= 11 && month >= 10) || (month === 12 && day <= 20)) {
      return automn;

    } else if ((month === 12 && day >= 21) || (month === 1 || month === 2) || (month <= 3 && day <= 20)) {
      return winter;

    } else {
      return spring;
    }
  }

  return (
    <div className="login fullWidthHeight pV30 pL30 borderBox">
      <img className="img" src={getImage()} alt="background saison"/>
      <div className="connexion flexVerticalCenter primaryColor">
        {displayView()}
      </div>
    </div>
  );
}

export default Home;